<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-l">
        <div class="mod2">
          <div class="item item1">
            <div class="s-pub-tk">
              <span class="bt">公共交通概况</span>
            </div>
          </div>
          <div class="item item2">
            <div class="txt">
              <p>
                <span class="tit">高铁站总数</span>
                <em>{{ publicTransportProfile.highSpeedRailStation  }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item3">
            <div class="txt">
              <p>
                <span class="tit"> 高速总长</span>
                <em>{{ publicTransportProfile.expressway}}</em>
                <span class="tit">公里</span>
              </p>
            </div>
          </div>
          <div class="item item4">
            <div class="txt">
              <p>
                <span class="tit">机场总数</span>
                <em>{{ publicTransportProfile.hotTotal }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item5">
            <div class="txt">
              <p>
                <span class="tit">定期航线</span>
                <em>{{ publicTransportProfile.kyTraffic }}</em>
                <span class="tit">条</span>
              </p>
            </div>
          </div>
          <div class="item item6">
            <div class="txt">
              <p>
                <span class="tit">公交站数量</span>
                <em>{{ publicTransportProfile.busStop }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item7">
            <div class="txt">
              <p>
                <span class="tit">汽车站数量</span>
                <em>{{ publicTransportProfile.motorStation }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="circle-container">
          <div class="circle circle1">
            <div class="circle_item">
              <div class="circle_top">全年旅客运输</div>
              <div class="circle_sec">
                <span class="circle_num">3764.33</span>
                <span class="circle_tip">万人次</span>
              </div>
            </div>
          </div>
          <div class="circle circle2">
            <div class="circle_item">
              <div class="circle_top">铁路运输量</div>
              <div class="circle_sec">
                <span class="circle_num">307.28</span>
                <span class="circle_tip">万人次</span>
              </div>
            </div>
          </div>
          <div class="circle circle3">
            <div class="circle_item">
              <div class="circle_top">公路运输量</div>
              <div class="circle_sec">
                <span class="circle_num">3290.95</span>
                <span class="circle_tip">万人次</span>
              </div>
            </div>
          </div>
          <div class="circle circle4">
            <div class="circle_item">
              <div class="circle_top">全年货运量</div>
              <div class="circle_sec">
                <span class="circle_num">3843.55</span>
                <span class="circle_tip">万吨</span>
              </div>
            </div>
          </div>
          <div class="circle circle5">
            <div class="circle_item">
              <div class="circle_top">铁路货运量</div>
              <div class="circle_sec">
                <span class="circle_num">1057.74</span>
                <span class="circle_tip">万吨</span>
              </div>
            </div>
          </div>
          <div class="circle circle6">
            <div class="circle_item">
              <div class="circle_top">公路运货量</div>
              <div class="circle_sec">
                <span class="circle_num">2784.86</span>
                <span class="circle_tip">万吨</span>
              </div>
            </div>
          </div>
          <div class="circle circle7">
            <div class="circle_item">
              <div class="circle_top">民航货运量</div>
              <div class="circle_sec">
                <span class="circle_num">2784.86</span>
                <span class="circle_tip">万吨</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con-r"></div>
    </div>
  </div>
</template>

  <script>
import Head from "@/components/head_sm.vue";
import axios from "axios";
export default{
  data() {
    return {
      headTitle: "",
      publicTransportProfile: {},
      loaded: false,
    };
  },
  components: {
    Head,
  },
  mounted() {
    this.loaded = true;
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/10kszhjt.json",{
          params:{
            // timestamp:new Date().getTime()
            random:Math.random()
          }
        })
        .then((res) => {
          // 处理响应
          // console.log("数据:"+res.data);
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);

          this.publicTransportProfile = jsonObject;
          //
          // this.schoolTotal = jsonObject.schoolTotal;
          //标题
          this.headTitle = jsonObject.bigTitle;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
  },
};
</script>

  <style scoped>
.page {
  background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/background.png) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}
.cen-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left:-120%;
  top: 0;
  z-index: 1;
  /* margin-left: -150px; */
}
.cen-map .pic {
  width: 100%;
  height: 100%;
  max-width: initial;
}
.mod2,.mod3 {
  height: 50%;
  width: 50%;
}
.mod2 .box{
  position: fixed;
  left: 28%;
  top: 12%;
  width: 1671px;
  height: 1522.35px;
  opacity: 1;
}
.mod3 .box {
  z-index: 999;
  position: fixed;
  right: 28%;
  top: 12%;
  height: 100%;
  width: 100%;
  opacity: 1;
}
.mod2 .item,.mod3 .item {
  width: 350px;
  padding: 1px;
  border-radius: 10px;
  background: rgba(0, 48, 104, 0.55);
  opacity: 1;
  box-sizing: border-box;
  border: 5px solid;
  border-image: linear-gradient(157deg, #58a1ff 30%, #01407d 74%) 5;

  backdrop-filter: blur(20px);
  position: absolute;
}
.mod2 .item .txt,.mod3 .item .txt {
  font-size: 52px;
  padding: 10px;
  font-weight: normal;
  letter-spacing: 10.4px;
  color: #45f5ff;
}
.mod2 .item .txt p,.mod3 .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod2 .item .tit,.mod3 .item .tit {
  font-family: Source Han Sans CN;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 0px;

  color: #ffffff;
}

.mod2 .item .txt *,.mod3 .item .txt * {
  font-family: "YouSheBiaoTiHei";
}

.mod2 .item1,.mod3 .item1 {
  left: 2%;
  bottom: 33%;
  width: 1016px;
  height: 69.9px;
  position: absolute;
  opacity: 1;

  background: rgba(0, 48, 104, 0.55);

  backdrop-filter: blur(20px);
}
.mod2 .item2,.mod3 .item2 {
  left: 2%;
  bottom: 25%;
  width: 503px;
  height: 120px;
}
.mod2 .item3,.mod3 .item3 {
  left: 16%;
  bottom: 25%;
  width: 503px;
  height: 120px;
}
.mod2 .item4,.mod3 .item4 {
  left: 2%;
  bottom: 15%;
  width: 503px;
  height: 120px;
}
.mod2 .item5,.mod3 .item5 {
  left: 16%;
  bottom: 15%;
  width: 503px;
  height: 120px;
}
.mod2 .item6,.mod3 .item6 {
  left: 2%;
  bottom: 5%;
  width: 503px;
  height: 120px;
}
.mod2 .item7,.mod3 .item7 {
  left: 16%;
  bottom: 5%;
  width: 503px;
  height: 120px;
}

.s-pub-tk {
  background: url(../assets/images/wt26.png) no-repeat left center #d1d3d600;
  background-size: auto 100%;
  padding-left: 100px;
  padding-right: 1px;
  align-items: center;
  height: 70px;
  bottom: 20px;
}
.s-pub-tk .bt {
  font-family: Source Han Sans CN;
  font-size: 46px;
  font-weight: normal;

  letter-spacing: 9.2px;

  color: #ffffff;
}

.con-1 {
  width: 100%;
  height: 100%;
}
.con-c .circle-container {
  margin-top: 5%;
  position: relative;
}
.con-c .circle-container .circle {
  background-image: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/hunan/hengyang/photo/zhengwu_pp.png);
  background-size: 100% 100%;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-container .circle .circle_item {
}
.circle-container .circle1 {
  position: absolute;
  margin-left: 15%;
  margin-top: 10%;
}
.circle-container .circle2 {
  position: absolute;
  left: 40%;
  top: 24%;
}
.circle-container .circle3 {
  position: absolute;
  margin-left: 66%;
  margin-top: 15%;
}
.circle-container .circle4 {
  position: absolute;
  margin-left: 28%;
  margin-top: 40%;
}
.circle-container .circle5 {
  position: absolute;
  margin-left: 51%;
  margin-top: 28%;
}
.circle-container .circle6 {
  position: absolute;
  margin-left: 5%;
  margin-top: 40%;
}
.circle-container .circle7 {
  position: absolute;
  margin-left: 77%;
  margin-top: 48%;
}
.circle .circle_item .circle_top {
  font-size: 35px;
  text-align: center;
  color: #fff;
  font-family: 锐字真言体;
  letter-spacing: 10px;
}
.circle .circle_item .circle_sec {
  margin-top: 10px;
  text-align: center;
}
.circle_sec .circle_num {
  color: #f39720;
  font-size: 44px;
}
.circle_sec .circle_tip {
  color: #fff;
  font-size: 40px;
}
</style>
